@import '../common/vars';
@import '../common/mixins';

.error__wrapper {
  a {
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
}

.error__row {
  max-width: 96%;
  width: 1240px;
  margin: 0 auto 1.875rem;
  border-bottom: solid 1px $header-gray;

  &:last-of-type {
    border-bottom: none;
  }

  @include breakpoint(1) {
    max-width: 90%;
  }
}

.error__row--background {
  background: #f5f7f8;
  padding: 2.25rem 0;
  border-bottom: none;
}

.error__row--full-width {
  max-width: 100%;
  width: 100%;
}

.error__row-title {
  color: $title-gray;
  text-transform: uppercase;
  font-size: 1.875rem;
  font-family: $font-pn-light;
  margin: 0 0 1.5rem;

  @include breakpoint(2) {
    font-size: 1.125rem;
    margin: 0 0 1rem;
  }
}


.error__message-wrapper {
  margin: 0 auto;
  max-width: 600px;
  text-align: center;

  @include breakpoint(1) {
    max-width: 90%;
  }

  h1 {
    color: $title-gray;
    font-size: 3.125rem;
    margin: 0 0 5px;
    font-family: $font-pn-bold;

    @include breakpoint(2) {
      font-size: 1.875rem;
    }
  }

  p {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    color: $light-black;
    font-family: $font-pn-light;

    @include breakpoint(2) {
      font-size: 1rem
    }
  }
}

.error__message--block {
  border: 1px solid #b2b2b2;
  border-top: none;
  height: 250px;

  @include breakpoint(1) {
    height: auto;
    border: none;
  }
}

.error__search-wrapper {
  text-align: center;

  .error__search-input {
    border: 1px solid #c9c9c9;
    border-radius: 3px;
    padding: 0 5px 0 10px;
    width: 640px;
    height: 40px;
    background-color: #ffffff;
    box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.29);
    font-size: 1rem;
    color: #000;
    outline: none;
    margin-right: 7px;
    font-family: $font-pn;

    @include breakpoint(2) {
      width: 500px;
    }

    @include breakpoint(1) {
      width: 200px;
    }
  }

  .error__search-submit {
    -webkit-appearance: none;
    font-family: $font-pn-semibold;
    border: 0;
    border-radius: 5px;
    padding: 0 18px;
    height: 40px;
    background-color: $mscp-blue;
    color: #fff;
    font-size: 1rem;
    transition: background-color .5s;

    @include breakpoint(1) {
      font-size: 1rem;
    }

    &:hover {
      cursor: pointer;
      background-color: $hover-blue;
    }
  }
}

.error__title--uppercase {
  text-transform: uppercase;
}

.error__pillar-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #fff;

  .error__pillar {
    box-sizing: border-box;
    flex-basis: calc(33.33% - 10px);

    &:nth-of-type(n+4) {
      border-top: solid 1px #d6d6d6;
    }

    @include breakpoint(1) {
      flex-basis: 100%;
      margin-bottom: 0.25rem;
      padding-top: 1.25rem;
      border-top: solid 1px #d6d6d6;

      &:first-child {
        padding-top: 0;
        border-top: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .error__pillar-title {
    display: block;
    color: $mscp-blue;
    font-family: $font-pn-semibold;
    text-transform: uppercase;
    font-size: 1rem;
    margin: 0 0 1.25rem;

    &:after {
      border: solid $hover-blue;
      border-width: 0 2px 2px 0;
      content: " ";
      display: inline-block;
      height: 7px;
      width: 7px;
      margin-left: 0.2rem;
      transform: rotate(-45deg);
    }
  }

  .error__pillar-link {
    display: block;
    font-family: $font-rb;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.2;
    margin: 0 0 1rem;
    color: $title-gray;
  }
}

.error__featured-wrapper {

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      flex-basis: 30%;
      padding-top: 0.75rem;
      margin-bottom: 1.875rem;

      &:nth-of-type(n+4) {
        border-top: solid 1px #f1f0ee;
      }

      @include breakpoint(2) {
        flex-basis: 45%;
        &:nth-child(n+3) {
          border-top: none;
        }
      }

      @include breakpoint(1) {
        flex-basis: 100%;
      }
    }
  }

  .title {
    font-family: $font-serif;
    font-size: 1.25rem;
    color: $title-gray;
    text-align: left;
    line-height: 1.2;
    text-decoration: none;

    @include breakpoint(2) {
      font-size: 1.125rem;
    }
  }

  .featimg {
    float: right;
    margin-left: 10px;
    width: 80px;
    height: 45px;
    object-fit: cover;
    object-position: center;
    font-family: "object-fit: cover; object-position: center;";
  }

  .teaser {
    display: none;
  }

  .byline {
    font-size: 0.875rem;
    font-family: $font-pn;
    line-height: 1.2;
    color: #767674;
    margin: 5px 0;
    text-align: left;

    span {
      &:after {
        content: "|";
        padding-left: 5px;
        font-style: normal;
      }

      .livefyre-commentcount:after {
        content: "";
        padding: 0;
      }

      &.lastVisible:after{
        content: "";
        padding: 0;
      }
    }

    .publication {
      font-style: italic;
    }

    .comments{
      background: url('/pi/global/icons/icon-comments-gray.svg') no-repeat 2px center;
      background-size:13px auto;
      padding-left:20px;
      display:none;
    }

    .comments.hasComments{
      display:inline;
    }

    .location{
      background: url('/pi/global/icons/icon-location-gray.svg') no-repeat left center;
      background-size:13px auto;
      padding-left:16px;
      border-left:0;
    }

    .trend{
      background: url('/pi/global/icons/icon-trending-red.svg') no-repeat 7px center;
      background-size:17px auto;
      display: none; //for initial release
    }

    .video{
      background: url('/pi/global/icons/icon-video-play-btn-gray.svg') no-repeat 2px center;
      background-size:15px auto;
      padding-left:20px;
    }

    .audio{
      background: url('/pi/global/icons/icon-audio-gray.svg') no-repeat 2px center;
      background-size:15px auto;
      padding-left:20px;
    }

    .slide{
      background: url('/pi/global/icons/icon-slideshow-gray.svg') no-repeat 2px center;
      background-size:15px auto;
      padding-left:20px;
    }

    span.date-range{
      background: url('/pi/global/icons/icon-calendar-gray.svg') no-repeat left center;
      background-size:15px auto;
      padding-left:18px;
    }

    @include breakpoint(1) {
      font-size: 0.875rem;
    }
  }

  .tag {
    font-size: 1rem;
    font-family: $font-rb;
    text-transform: uppercase;
  }

  .newstag {
    color: $red;
  }

  .conftag {
    color: $green;
  }

  .cmetag {
    color: $red;
  }

}














